(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@vanilla-extract/dynamic"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@vanilla-extract/dynamic", "react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@vanilla-extract/dynamic"), require("react")) : factory(root["@vanilla-extract/dynamic"], root["react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__479__, __WEBPACK_EXTERNAL_MODULE__156__) {
return 