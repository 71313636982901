import React from 'react'

import styled from '@emotion/styled'
import { ScreenHelmet } from '@karrotframe/navigator'

const Page404: React.FC = () => {
  return (
    <Container>
      <ScreenHelmet />
      Not Found
    </Container>
  )
}

const Container = styled.div``

export default Page404
