import './App.css'

import React, {useEffect, useState} from 'react'

import { Navigator, Screen } from '@karrotframe/navigator'
import VConsole from 'vconsole'

import Page404 from './components/_pages/Page404'
import PageHome from './components/_pages/PageHome'
import PagePop from './components/_pages/PagePop'
import PagePullToRefresh from './components/_pages/PagePullToRefresh'
import PagePush from './components/_pages/PagePush'
import PageReplace from './components/_pages/PageReplace'
import PageReplaceInUseEffect from './components/_pages/PageReplaceInUseEffect'
import PageScreenHelmet from './components/_pages/PageScreenHelmet'
import PageTabs from './components/_pages/PageTabs'
import PageUseParams from './components/_pages/PageUseParams'
import PageUseQueryParams from './components/_pages/PageUseQueryParams'

import {
  dataPlugin,
  loggerMiddlewareBeforePushPlugin,
} from '@karrotframe/navigator-plugin'

const App: React.FC = () => {
  const [theme, setTheme] = useState<'Cupertino' | 'Android'>('Cupertino')

  const switchTheme = () => {
    setTheme((prev) => (prev === 'Cupertino' ? 'Android' : 'Cupertino'))
  }

  useEffect(() => {
    const vConsole = new VConsole({ theme: 'dark' })

    return () => {
      if (!vConsole) return
      vConsole.destroy()
    }
  }, [])


  return (
    <Navigator
      theme={theme}
      onDepthChange={(depth) => {
        console.log(depth)
      }}
      onClose={() => {
        window.alert('Close button clicked!')
      }}
      plugins={[dataPlugin, loggerMiddlewareBeforePushPlugin]}
    >
      <Screen
        path="/"
        component={() => (
          <PageHome currentTheme={theme} switchTheme={switchTheme} />
        )}
      />
      <Screen path="/screenHelmet" component={PageScreenHelmet} />
      <Screen path="/push" component={PagePush} />
      <Screen path="/pop" component={PagePop} />
      <Screen path="/replace" component={PageReplace} />
      <Screen path="/replaceInUseEffect" component={PageReplaceInUseEffect} />
      <Screen path="/useParams/:param" component={PageUseParams} />
      <Screen path="/useQueryParams" component={PageUseQueryParams} />
      <Screen path="/tabs" component={PageTabs} />
      <Screen path="/pulltorefresh" component={PagePullToRefresh} />
      <Screen path="*" component={Page404} />
    </Navigator>
  )
}

export default App
